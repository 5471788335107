// Import dependencies
import React from "react"

// Import components
import HowItWorks from "./howItWorks"
import TitleSection from "../sections/titleSection"

// Import styles
import "../../pages/index.css"
import { Constants } from "../../constants/Constants"
import { STRIPE_CONSTANT, DEPLOY_TYPE } from "../../constants/stripe"

/*
    How it works section for index pagea
*/
const HowItWorksSection = ({ step1, step2, step3, step4, step5 }) => (
  <>

        <div className="pricing_page_title">
          <h1>Take the work out of recycling</h1>
          <p>Find the items you want to recycle, sell them to us, and we’ll handle the rest</p>
        </div>
    <HowItWorks
      title="Find the items you want to recycle"
      image={step1}
      reverse
      links={[{text: "Still can’t find your item?", link: "https://help.reinin.sg/"}]}
      paragraph={1}
    />
    <HowItWorks
      title="Get an instant offer"
      image={step2}
      links={[{text: "Reinin price-match guarantee", link: "https://help.reinin.sg/"}, {text: "Earn more as a member", link: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"] + "pricing"}]}
      paragraph={2}
    />
    <HowItWorks
      title="Book a pickup on-demand"
      image={step3}
      reverse
      links={[{text: "How to prepare items", link: "https://help.reinin.sg/"}, {text: "Free pickups for members", link: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"] + "pricing"}]}
      paragraph={3}
    />
    <HowItWorks
      title="Let us do the hard work"
      image={step4}
      links={[{text: "Will my device data be safe?", link: "https://help.reinin.sg/"}, {text: "Is there a returns policy?", link: "https://help.reinin.sg/"}]}
      paragraph={4}
    />
    <HowItWorks
      title="Get paid for recycling"
      image={step5}
      reverse
      links={[{text: "Get paid in 24-hrs as a member", link: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"] + "pricing"}, {text: "The $100 Reinin referal program!", link: "https://help.reinin.sg/"}]}
      paragraph={5}
    />

  </>
)

export default HowItWorksSection
